import { useEffect } from "react";
import styles from "./ContactsPage.module.less";

import contactsBackground from "app/assets/contactsBackground.webp";

interface Props {
  setBackgroundImage: (src: string) => void;
}

const ContactsPage = ({ setBackgroundImage }: Props) => {
  useEffect(() => {
    setBackgroundImage(contactsBackground);
  }, [setBackgroundImage]);

  return (
      <div className={styles.container}>
        <div className={styles.text}>
          <p>ООО «ПФК «Мобил-лайн»</p>
          <p>
            <a href="tel:+8 (3412) 900-920">+8 (3412) 900-920</a>
          </p>
          <p>426035, г.Ижевск, ул.Тимирязева, д.3, оф.1</p>
          <p>
            <a href="mailto:driveconcert@yandex.ru">driveconcert@yandex.ru</a>
          </p>
          <p>
            ИНН 1832070675<br/>
            КПП 184001001<br/>
            ОГРН 1081832008818
          </p>
        </div>
        <div className={styles.text} style={{marginTop: "20px"}}>
          <p>ООО «ПКП «Мобил-лайн»</p>
          <p>
            <a href="tel:+8 (3412) 900-920">+8 (3412) 900-920</a><br/>
            <a href="tel:+8 (3412) 63-86-10">+8 (3412) 63-86-10</a>
          </p>
          <p>426035, г.Ижевск, ул.Тимирязева, д.3, оф.1</p>
          <p>
            <a href="mailto:Rondamit2010@yandex.ru">Rondamit2010@yandex.ru</a>
          </p>
          <p>
            ИНН 1840001403<br/>
            КПП 184101001<br/>
            ОГРН 1111840007949
          </p>
        </div>
      </div>
  );
};

export default ContactsPage;
